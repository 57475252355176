<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="tile is-ancestor">
            <div class="tile is-parent is-vertical is-12">
                <article class="tile is-child box">
                    <div class="columns">
                        <div class="column is-12"> 
                            <b-table v-if="lista.length != 0" :data="lista" 
                                :default-sort-direction="defaultOrdenar"
                                :default-sort="[ordenarPor, ordenar]"
                                sort-icon-size="is-small"
                                @sort="onSort">                               
                                <template>

                                    <b-table-column  v-slot="props" field="guiaId" :label="$t('FECHAMENTOCAIXA.GUIA')" sortable>
                                        <router-link :to="{ name: 'guia', params: { id: props.row.guiaId }}">
                                            {{ props.row.guiaId }}
                                        </router-link>
                                    </b-table-column>

                                    <b-table-column  v-slot="props" field="dataDeEmissao" :label="$t('FECHAMENTOCAIXA.DATAEMISSAO')" sortable>
                                        {{$moment(props.row.dataDeEmissao).format("DD/MM/YYYY HH:mm") }}
                                    </b-table-column>

                                    <b-table-column  v-slot="props" field="pacienteNome" :label="$t('FECHAMENTOCAIXA.PACIENTE')" sortable>
                                        <router-link :to="{ name: 'paciente', params: { id: props.row.pacienteId }}">
                                            {{ props.row.pacienteNome }}
                                        </router-link>
                                    </b-table-column>

                                    <b-table-column  v-slot="props" field="convenioNome" :label="$t('FECHAMENTOCAIXA.CONVENIO')" sortable>
                                        <router-link :to="{ name: 'convenio', params: { id: props.row.convenioId }}">
                                            {{ props.row.convenioNome }}
                                        </router-link>
                                    </b-table-column>

                                    <b-table-column  v-slot="props" field="localDeOrigemNome" :label="$t('FECHAMENTOCAIXA.LOCALORIGEM')" sortable>
                                        <router-link :to="{ name: 'local', params: { id: props.row.localDeOrigemId }}">
                                            {{ props.row.localDeOrigemNome }}
                                        </router-link>
                                    </b-table-column>

                                    <b-table-column  v-slot="props" field="unidadeNome" :label="$t('FECHAMENTOCAIXA.UNIDADE')" sortable>
                                        <router-link :to="{ name: 'unidade', params: { id: props.row.unidadeId }}">
                                            {{ props.row.unidadeNome }}
                                        </router-link>
                                    </b-table-column>

                                    <b-table-column v-slot="props" field="funcionarioNome" :label="$t('FECHAMENTOCAIXA.FUNCIONARIO')" sortable>
                                        <router-link :to="{ name: 'funcionario', params: { id: props.row.funcionarioId }}">
                                            {{ props.row.funcionarioNome }}
                                        </router-link>
                                    </b-table-column>

                                    <b-table-column v-slot="props" field="valorTotal" :label="$t('FECHAMENTOCAIXA.VALOR')" sortable>
                                        {{ formatPrice(props.row.valorTotal) }}
                                    </b-table-column>

                                    <b-table-column v-slot="props" field="desconto" :label="$t('FECHAMENTOCAIXA.DESCONTO')" sortable>
                                        {{ formatPrice(props.row.desconto) }}
                                    </b-table-column>

                                    <b-table-column v-slot="props" field="Débito (R$)" :label="$t('FECHAMENTOCAIXA.DEBITO')" sortable>
                                        {{ formatPrice(props.row.valorTotal - props.row.desconto) }}
                                    </b-table-column>

                                    <b-table-column v-slot="props" field="receberId" :label="$t('FECHAMENTOCAIXA.RECEBER')" sortable>
                                        <router-link :to="{ name: 'contas-receber', params: { id: props.row.receberId }}">
                                            {{ props.row.receberId }}
                                        </router-link>
                                    </b-table-column>
                                </template>
                            </b-table>
                        </div>     
                    </div>                             
                    <div class="columns">   
                        <div class="column is-12">                 
                            <article class="tile is-child box">
                                <label class="label">{{$t('FECHAMENTOCAIXA.GUIASFATURADAS')}}:</label>               
                                <div class="columns">  
                                    <b-field :label="$t('FECHAMENTOCAIXA.VALOR')">
                                        <b-input type="text" class="column" v-model="totalValorFaturado" readonly></b-input>
                                    </b-field> 
                                    <b-field :label="$t('FECHAMENTOCAIXA.DESCONTO')">
                                        <b-input type="text" class="column" v-model="totalDescontoFaturado" readonly></b-input>
                                    </b-field> 
                                    <b-field :label="$t('FECHAMENTOCAIXA.DEBITO')">
                                        <b-input type="text" class="column" v-model="totalFaturado" readonly></b-input>
                                    </b-field> 
                                    <b-field :label="$t('FECHAMENTOCAIXA.QUANTIDADE')">
                                        <b-input type="text" class="column" v-model="lista.filter(item => item.receberId != null).length" readonly></b-input>
                                    </b-field>
                                </div>     
                            </article>                        
                            <article class="tile is-child box">
                                <label class="label">{{$t('FECHAMENTOCAIXA.GUIASNAOFATURADAS')}}:</label>           
                                <div class="columns">  
                                    <b-field :label="$t('FECHAMENTOCAIXA.VALOR')">
                                        <b-input type="text" class="column" v-model="totalValorNaoFaturado" readonly></b-input>
                                    </b-field> 
                                    <b-field :label="$t('FECHAMENTOCAIXA.DESCONTO')">
                                        <b-input type="text" class="column" v-model="totalDescontoNaoFaturado" readonly></b-input>
                                    </b-field> 
                                    <b-field :label="$t('FECHAMENTOCAIXA.DEBITO')">
                                        <b-input type="text" class="column" v-model="totalNaoFaturado" readonly></b-input>
                                    </b-field> 
                                    <b-field :label="$t('FECHAMENTOCAIXA.QUANTIDADE')">
                                        <b-input type="text" class="column" v-model="lista.filter(item => item.receberId == null).length" readonly></b-input>
                                    </b-field>
                                </div> 
                            </article> 
                        </div>     
                    </div>     
                </article>                
            </div>
        </div>
    </section>
</template>

<script>
    import titlebar from '@/components/titlebar.vue'

    export default {
        components: {
            titlebar
        },
        data() {
            return {
                lista: [],
                ordenarPor: 'receberId',
                ordenar: 'desc',
                defaultOrdenar: 'desc',
                loading: false,
                totalValorFaturado: 0,
                totalDescontoFaturado: 0,
                totalFaturado: 0,
                totalValorNaoFaturado: 0,
                totalDescontoNaoFaturado: 0,
                totalNaoFaturado: 0
            }
        },
        computed: {
            titleStack() {
                return [
                    this.$t('SIDEBAR.FINANCEIRO'),
                    this.$t('FECHAMENTOCAIXA.TITULO')
                ]
            }
        },
        mounted() {
            this.loadAsyncData()
        },
        methods: {
            onSort(field, order) {
                this.ordenarPor = field
                this.ordenar = order
            },
            loadAsyncData() {
                this.loading = true

                this.$http.get(`/api/financeiro/fechamentodecaixa?dataInicial=${this.$moment(this.$route.params.dataInicial).format('YYYY-MM-DDTHH:mm:ss')}&dataFinal=${this.$moment(this.$route.params.dataFinal).format('YYYY-MM-DDTHH:mm:ss')}`)
                    .then(({ data }) => {
                        this.lista = data;

                        for (let i = 0; i < this.lista.length; i++) {
                            if(this.lista[i].receberId != null) {
                                this.totalValorFaturado += this.lista[i].valorTotal;
                                this.totalDescontoFaturado += this.lista[i].desconto;
                            }
                            else {
                                this.totalValorNaoFaturado += this.lista[i].valorTotal;
                                this.totalDescontoNaoFaturado += this.lista[i].desconto;
                            }
                        }

                        this.totalFaturado = this.formatPrice(this.totalValorFaturado - this.totalDescontoFaturado);
                        this.totalNaoFaturado = this.formatPrice(this.totalValorNaoFaturado - this.totalDescontoNaoFaturado);
                        this.totalValorFaturado = this.formatPrice(this.totalValorFaturado);
                        this.totalDescontoFaturado = this.formatPrice(this.totalDescontoFaturado);
                        this.totalValorNaoFaturado = this.formatPrice(this.totalValorNaoFaturado);
                        this.totalDescontoNaoFaturado = this.formatPrice(this.totalDescontoNaoFaturado);

                        this.loading = false
                    })
                    .catch((error) => {
                        this.lista = []
                        this.loading = false
                        throw error
                    })
            },
            formatPrice(value) {
                const val = (value/1).toFixed(2).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            }
        }
    }
</script>