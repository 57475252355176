<template>
    <div>                    
        <div class="box is-hero-bar is-main-hero column is-12" v-if="examesAtrasados != null && examesAtrasados.length > 0">
            <div class="panel">
                <div v-for="(exame, index) in examesAtrasados" :key="index">
                    <div class="panel-block columns is-multiline">                        
                        <div class="column is-2">
                            <span>
                                <small>{{$t('EXAMESATRASADOS.PEDIDO')}}:</small>
                                <br>               
                                <router-link :to="{ name: 'portalconvenioguia', params: { id: exame.guiaId }}">
                                    <b>{{exame.guiaId}}</b>
                                </router-link>
                            </span>
                        </div>                        
                        <div class="column is-2" v-if="exame.guiaReferencia">
                            <small>{{$t('EXAMESATRASADOS.REFERENCIA')}}:</small>
                            <br>
                            <b>{{exame.guiaReferencia}}</b>
                        </div>                       
                        <div class="column is-4">
                            <small>{{$t('EXAMESATRASADOS.PACIENTE')}}:</small>
                            <br>
                            <b>{{exame.pacienteNome}}</b>
                        </div>              
                        <div class="column is-7">
                            <small>{{$t('EXAMESATRASADOS.EXAMES')}}:</small>  
                            <br>                          
                            <b-tag v-for="(exameApelido, j) in exame.examesApelidos" v-bind:key="j"
                                :type="'default'">
                                {{ exameApelido }}
                            </b-tag>
                        </div>                
                        <div class="column is-12">
                            <small>{{$t('EXAMESATRASADOS.MOTIVO')}}:</small>
                            <br>
                            <span>{{exame.dados}}</span>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapActions, mapState } from 'vuex'

    export default {

        data() {
            return {
                examesAtrasados:[]
            };
        },               
        computed: {
            ...mapState([
                'config',
                'usuario'
            ])            
        },
        methods:{

            carregarExamesAtrasados(){
                if(this.examesAtrasosCount != 0){
                    this.$http.get('/api/portal/ExamesAtrasos')
                        .then(res => {
                            this.examesAtrasados = res.data;
                        });
                }
            },          
        },

        created(){
            this.carregarExamesAtrasados();
        },         
    }

</script>