<template>
    <section>
        <b-loading :active.sync="isLoading"></b-loading>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="tile is-ancestor">
            <div class="tile is-parent is-vertical is-12">
                <article class="tile is-child box">
                     <div class="columns">
                        <div class="column is-10">
                            <periodoHora
                            :dataInicial.sync="dataInicial"
                            :dataFinal.sync="dataFinal"
                            :placeHolder="$t('AGENDARELACAO.SELECIONEPERIODO')"
                            :selecionadas="[dataInicial,dataFinal]"
                            />
                        </div>
                        <div class="column is-2">
                            <button class="button is-primary" type="button" @click="filtrar({dataInicial, dataFinal})">{{$t('AGENDARELACAO.FILTRAR')}}</button>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-3">
                            <b>{{$t('AGENDARELACAO.FILTRARRESPONSAVEL')}}</b>
                            <b-select v-model="funcionarioIdFiltro">
                                <option :value="todos">Todos</option>
                                <template v-for="(item, index) in this.funcionariosAtivosFiltro">
                                    <option v-if="funcionarioIdFiltro == item.id" selected v-bind:key="index" :value="item.id">
                                        {{item.nome}}
                                    </option>
                                    <option v-else v-bind:key="index" :value="item.id">
                                        {{item.nome}}
                                    </option>
                                </template>
                            </b-select>
                        </div>
                        <div class="column is-3">
                            <b>{{$t('AGENDARELACAO.FILTRARAGENDAMENTO')}}</b>
                            <b-select v-model="tipoAgendamentoIdFiltro" expanded>
                                <option :value="todos">Todos</option>
                                <template v-for="(item, index) in this.tiposAgendamentoAtivosFiltro">
                                    <option v-if="tipoAgendamentoIdFiltro == item.id" selected v-bind:key="index" :value="item.id">
                                        {{item.nome}}
                                    </option>
                                    <option v-else v-bind:key="index" :value="item.id">
                                        {{item.nome}}
                                    </option>
                                </template>
                            </b-select>
                        </div>
                        <div class="column is-3">
                            <b>{{$t('AGENDARELACAO.FILTRARSTATUS')}}</b>
                            <b-select v-model="statusFiltro" expanded>
                                <option :value="todos">Todos</option>
                                <template v-for="(item, index) in this.statusAgenda">
                                    <option v-if="statusFiltro == item.valor" selected v-bind:key="index" :value="item.valor">
                                        {{item.descricao}}
                                    </option>
                                    <option v-else v-bind:key="index" :value="item.valor">
                                        {{item.descricao}}
                                    </option>
                                </template>
                            </b-select>
                        </div>                        
                    </div>

                     <br />

                    <b-table :data="model" striped hoverable>
                        <template  >
                            <b-table-column v-slot="props" field="data" :label="$t('AGENDARELACAO.DATA')">
                                {{ props.row.dataHora | datahora}}
                            </b-table-column>
                            <b-table-column  v-slot="props" field="paciente" :label="$t('AGENDARELACAO.PACIENTE')">
                                {{ props.row.pacienteNome}}
                            </b-table-column> 
                            <b-table-column  v-slot="props" field="endereco" :label="$t('AGENDARELACAO.ENDERECO')">
                                {{ props.row.pacienteEndereco}}
                            </b-table-column>                               
                            <b-table-column  v-slot="props" field="bairro" :label="$t('AGENDARELACAO.BAIRRO')">
                                {{ props.row.pacienteBairro}}
                            </b-table-column>                               
                            <b-table-column  v-slot="props" field="cep" :label="$t('AGENDARELACAO.CEP')">
                                {{ props.row.pacienteCEP}}
                            </b-table-column>                               
                            <b-table-column  v-slot="props" field="cidade" :label="$t('AGENDARELACAO.CIDADE')">
                                {{ props.row.pacienteCidade}}
                            </b-table-column>                               
                            <b-table-column  v-slot="props" field="fone" :label="$t('AGENDARELACAO.FONE')">
                                {{ props.row.pacienteFone}}
                            </b-table-column>                               
                            <b-table-column  v-slot="props" field="Observacao" :label="$t('AGENDARELACAO.OBSERVACAO')">
                                {{ props.row.observacao}}
                            </b-table-column>
                            <b-table-column  v-slot="props" field="tipoagendamento" :label="$t('AGENDARELACAO.TIPOAGENDAMENTO')">
                                {{ props.row.tipoAgendamentoNome}}
                            </b-table-column>
                            <b-table-column  v-slot="props" field="funcionarioresponsavel" :label="$t('AGENDARELACAO.FUNCIONARIORESPONSAVEL')">
                                {{ props.row.funcionarioNome}}
                            </b-table-column>   
                            <b-table-column v-slot="props" field="log" :label="$t('AGENDARELACAO.HISTORICO')">                                
                                    <b-button type="is-info" native-type="button" icon-left="clock" 
                                    class="button-delete" @click="exibirHistorico(props.row)" ></b-button>                                
                            </b-table-column>                                                                                                                                                                                                                                

                        </template>
                    </b-table>
                </article>
            </div>
        </div>        
    </section>
</template>

<style lang="scss" scoped>

</style>
<script>
    import titlebar from '@/components/titlebar.vue'
    import periodoHora from '@/components/periodoHora.vue'
    import moment from 'moment';    
    import historicoAlteracoes from "@/components/historicoAlteracoes.vue";

    export default {
        components: {
            titlebar,
            periodoHora            
        },
        computed: {
            titleStack() {
                return [
                    this.$t('AGENDARELACAO.ATENDIMENTO'),
                    this.$t('AGENDARELACAO.RELACAOAGENDAMENTO'),
                ]
            }
        },
        data() {
            return {
                dataInicial: moment().add('month',-1).set('hours',0).set('minutes',0).toDate(),
                dataFinal: moment().set('hours',23).set('minutes',59).toDate(),
                model: [],
                atendimentoInterno: true,
                coletaDomiciliar: true,
                item: {
                    tipoAgendamento: null,
                },
                funcionarioIdFiltro: null,
                funcionariosAtivosFiltro: [],
                tipoAgendamentoIdFiltro: null,
                statusFiltro: null,
                tiposAgendamentoAtivosFiltro: [],
                statusAgenda:[],
                isLoading: false,
                loadedData: false,
                todos: null,
            }
        },
        created(){
            try {
                if (this.$route.query.dataInicial) this.dataInicial = moment(this.$route.query.dataInicial,'DD/MM/YYYY HH:mm').toDate();
                if (this.$route.query.dataFinal) this.dataFinal = moment(this.$route.query.dataFinal,'DD/MM/YYYY HH:mm').toDate();            

                

                this.loadData();
            }catch(ex){
                console.error(ex);
            }
        },
        mounted() {
            this.$http.get('/api/search/FuncionariosAtivos')
            .then(res => res.data)
            .then(data => {
                if (data != null) {
                    this.funcionariosAtivosFiltro = data;
                }
            });

            this.$http.get('/api/search/ListarStatusAgenda')
            .then(res => res.data)
            .then(data => {
                if (data != null) {
                    this.statusAgenda = data;
                }
            });

            this.$http.get('/api/search/tiposagendamentos' + 
                (this.$route.query.unidadeId != null ? `?unidadeId=${this.$route.query.unidadeId}` : ''))
            .then(res => res.data)
            .then(data => {
                if (data != null) {
                    this.tiposAgendamentoAtivosFiltro = data;
                }
            });            
        },
        filters: {
            datahora:  (valor) => {
            if (!valor) return null;    
            
            return  moment(valor).format('DD/MM/YYYY HH:mm:ss');
            }
        },
        methods: {   
            
            exibirHistorico(item) {
                this.$buefy.modal.open({
                    parent: this,
                    component: historicoAlteracoes,
                    props: {
                        tabela: '',
                        chave: item.id,
                        toggle: true,
                        agendaLog: true,
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },            
            filtrar(datas) {
                this.dataInicial = datas.dataInicial;
                this.dataFinal = datas.dataFinal;
                this.loadData();
            },

            loadData(){
                const params = [];

                if (this.dataInicial != null) params.push(`dataInicial=${moment(this.dataInicial).format()}`);
                if (this.dataFinal != null) params.push(`dataFinal=${moment(this.dataFinal).format()}`);
                if (this.statusFiltro != null) params.push(`status=${this.statusFiltro}`);
                if (this.tipoAgendamentoIdFiltro != null) params.push(`tipoAgendamentoId=${this.tipoAgendamentoIdFiltro}`);
                if (this.funcionarioIdFiltro != null) params.push(`funcionarioId=${this.funcionarioIdFiltro}`);                
                if (this.$route.query.unidadeId != null) params.push(`unidadeId=${this.$route.query.unidadeId}`);            
                
                this.isLoading = true;

                try {
                    this.$http.get(`/api/atendimento/RelacaoAgenda?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {
                            if (data != null) {
                                this.model = data?.map(d => {
                                    return {
                                        ...d,
                                        dataHora: moment(d.dataHora).toDate(),
                                        atendimentoInterno: !d.guiaTipoDeLocalColetaDomiciliar,
                                        atendimentoDomiciliar: d.guiaTipoDeLocalColetaDomiciliar,
                                    }
                                });
                            }
                            this.isLoading = false;
                            this.loadedData = true;
                        }).catch(e => {
                            throw e;
                        });
                }catch(e){
                    console.error(e);
                    this.isLoading = false;
                }

            },
           
        }
    }
</script>