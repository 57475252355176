<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="tile is-ancestor">
            <div class="tile is-parent is-vertical is-12">
                <article class="tile is-child box">
                    <div class="columns">
                        <div class="column is-12"> 
                            <div class="field has-text-centered">
                                <b-switch v-model="parcelasVencidas">
                                    {{$t('RELACAODEBITOS.SOMENTEPARCELASVENCIDAS')}}
                                </b-switch>
                            </div>
                            <b-table v-if="lista.length != 0" :data="lista" 
                                :default-sort-direction="defaultOrdenar"
                                :default-sort="[ordenarPor, ordenar]"
                                sort-icon-size="is-small"
                                @sort="onSort">                               
                                <template >

                                    <b-table-column  v-slot="props" field="receberId" :label="$t('RELACAODEBITOS.CODIGO')" sortable>
                                        <router-link :to="{ name: 'contas-receber', params: { id: props.row.receberId }}">
                                            {{ props.row.receberParcelaId }}
                                        </router-link>
                                    </b-table-column>

                                    <b-table-column  v-slot="props" field="dataDeVencimento" :label="$t('RELACAODEBITOS.DATAVENCIMENTO')" sortable>
                                        {{ $moment(props.row.dataDeVencimento).format("DD/MM/YYYY") }}
                                    </b-table-column>

                                    <b-table-column  v-slot="props" field="pacienteNome" :label="$t('RELACAODEBITOS.PACIENTE')" sortable>
                                        <router-link :to="{ name: 'paciente', params: { id: props.row.pacienteId }}">
                                            {{ props.row.pacienteNome }}
                                        </router-link>
                                    </b-table-column>

                                    <b-table-column  v-slot="props" field="convenioNome" :label="$t('RELACAODEBITOS.CONVENIO')" sortable>
                                        <router-link :to="{ name: 'convenio', params: { id: props.row.convenioId }}">
                                            {{ props.row.convenioNome }}
                                        </router-link>
                                    </b-table-column>

                                    <b-table-column  v-slot="props" field="valor" :label="$t('RELACAODEBITOS.VALOR')" sortable>
                                        {{ formatPrice(props.row.valor) }}
                                    </b-table-column>
                                </template>
                            </b-table>
                        </div>     
                    </div>  
                    <div class="columns">   
                        <div class="column is-12">                 
                            <article class="tile is-child box">           
                                <div class="columns">  
                                    <b-field :label="$t('RELACAODEBITOS.TOTALPACIENTE')">
                                        <b-input type="text" class="column" v-model="listaCompleta.filter(item => item.receberId != null).length" readonly></b-input>
                                    </b-field>
                                    <b-field :label="$t('RELACAODEBITOS.TOTAL')">
                                        <b-input type="text" class="column" v-model="totalValor" readonly></b-input>
                                    </b-field>
                                </div>     
                            </article>
                        </div>     
                    </div>  
                </article>                
            </div>
        </div>
    </section>
</template>

<script>
    import titlebar from '@/components/titlebar.vue'

    export default {
        components: {
            titlebar
        },
        data() {
            return {
                lista: [],
                listaCompleta: [],
                ordenarPor: 'pacienteNome',
                ordenar: 'asc',
                defaultOrdenar: 'asc',
                loading: false,
                totalValor: 0,
                parcelasVencidas: false
            }
        },
        computed: {
            titleStack() {
                return [
                    this.$t('SIDEBAR.FINANCEIRO'),
                    this.$t('RELACAODEBITOS.TITULO')
                ]
            }
        },
        mounted() {
            this.loadAsyncData()
        },
        watch: {
            parcelasVencidas(){
                if(this.parcelasVencidas){
                    this.lista = this.listaCompleta.filter(item => item.dataDeVencimento < this.$moment(new Date()).format("YYYY-MM-DD"));
                }
                else {
                    this.lista = this.listaCompleta;
                }
            }
        },
        methods: {
            onSort(field, order) {
                this.ordenarPor = field
                this.ordenar = order
            },
            loadAsyncData() {
                this.loading = true

                this.$http.get(`/api/financeiro/relacaodedebitos?sintetizado=${(this.$route.params.sintetizado != null ? this.$route.params.sintetizado : false)}&dataInicial=${this.$moment(this.$route.params.dataInicial).format('YYYY-MM-DDTHH:mm:ss')}&dataFinal=${this.$moment(this.$route.params.dataFinal).format('YYYY-MM-DDTHH:mm:ss')}`)
                    .then(({ data }) => {
                        this.lista = data;
                        this.listaCompleta = data;

                        for (let i = 0; i < this.lista.length; i++) {
                            this.totalValor += this.lista[i].valor;
                        }
                        this.totalValor = this.formatPrice(this.totalValor);


                        this.loading = false
                    })
                    .catch((error) => {
                        this.lista = []
                        this.loading = false
                        throw error
                    })
            },
            formatPrice(value) {
                const val = (value/1).toFixed(2).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            }
        }
    }
</script>