<template>
    <section>
        <b-loading :active.sync="loading"></b-loading>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="columns box">
            <div class="column is-5">
                <b-field>
                    <b-input :placeholder="$t('CONTASRECEBERLISTAR.PLACEHOLDERPROCURAR')"
                             v-model="procurar"
                             icon="magnify"
                             @input="loadAsyncData"></b-input>
                </b-field>
            </div>
            <div class="column">
                <div class="is-flex is-justified-between">
                    <router-link
                        v-if="isInRole('receber-alterar')"
                        :to="{ name: 'contas-receber' }"
                        class="button is-primary"
                        >
                        {{$t('CONTASRECEBERLISTAR.INCLUIR')}}
                    </router-link>
                </div>                
            </div>           
            <div class="column is-2">
                <form @submit.prevent="abrirCadastroInput">
                    <b-field>
                        <b-input placeholder="Código"
                                 v-model="id"
                                 type="text"
                                 icon-right="edit"
                                 icon-right-clickable
                                 @icon-right-click="abrirCadastroInput">
                        </b-input>
                    </b-field>
                </form>
            </div>
        </div>
        <b-table :data="model.lista"
                 :loading="loading"
                 paginated
                 striped
                 hoverable
                 backend-pagination
                 :total="$t('CONTASRECEBERLISTAR.')"
                 @page-change="onPageChange"
                 :aria-next-label="$t('CONTASRECEBERLISTAR.PROXPAGINA')"
                 :aria-previous-label="$t('CONTASRECEBERLISTAR.PAGANTERIOR')"
                 :aria-page-label="$t('CONTASRECEBERLISTAR.PAGINA')"
                 :aria-current-label="$t('CONTASRECEBERLISTAR.PAGATUAL')"
                 backend-sorting
                 :default-sort-direction="defaultOrdenar"
                 :default-sort="[ordenarPor, ordenar]"
                 sort-icon-size="is-small"
                 @sort="onSort">

            <template  @click="abrirCadastro(props.row.id)">
                <b-table-column  v-slot="props" field="id" :label="$t('CONTASRECEBERLISTAR.ID')">
                    {{ props.row.id }}
                </b-table-column>

                <b-table-column  v-slot="props" field="Data" :label="$t('CONTASRECEBERLISTAR.DATA')">
                    {{ props.row.data | data }}
                </b-table-column>

                <b-table-column  v-slot="props" field="Convenio" :label="$t('CONTASRECEBERLISTAR.CONVENIO')">
                    {{ props.row.convenio }}
                </b-table-column>

                <b-table-column  v-slot="props" field="Paciente" :label="$t('CONTASRECEBERLISTAR.PACIENTE')">
                    {{ props.row.paciente }}
                </b-table-column>        

                <b-table-column  v-slot="props" field="Departamento" :label="$t('CONTASRECEBERLISTAR.DEPARTAMENTO')">
                    {{ props.row.departamento }}
                </b-table-column>        

                <b-table-column  v-slot="props" field="ContaCorrente" :label="$t('CONTASRECEBERLISTAR.CONTACORRENTE')">
                    {{ props.row.contaCorrente }}
                </b-table-column>             

                <b-table-column  v-slot="props" field="QtdeExames" :label="$t('CONTASRECEBERLISTAR.QTDEXAMES')">
                    {{ props.row.qtdeExames }}
                </b-table-column> 

                <b-table-column  v-slot="props" field="Valor" :label="$t('CONTASRECEBERLISTAR.VALOR')">
                    {{ props.row.valor | currency}}
                </b-table-column>  

                <b-table-column v-slot="props" field="" :label="$t('CONTASRECEBERLISTAR.PDFSINTETICO')">
                    <b-button icon-left="eye" class="is-primary center" v-if="props.row.qtdeExames > 0" @click.prevent.stop="download('ContasReceberPDFSintetico', props.row)"></b-button>
                </b-table-column>                              

                <b-table-column v-slot="props" field="" :label="$t('CONTASRECEBERLISTAR.PDF')">
                    <b-button icon-left="eye" class="is-success center" v-if="props.row.qtdeExames > 0"  @click.prevent.stop="download('ContasReceberPDF',props.row)"></b-button>
                </b-table-column>                              

                <b-table-column v-slot="props" field="" :label="$t('CONTASRECEBERLISTAR.XLS')">
                    <b-button icon-left="download" class="is-warning center" v-if="props.row.qtdeExames > 0" @click.prevent.stop="download('ContasReceberXLS',props.row, 'xls')"></b-button>
                </b-table-column>                  

                <b-table-column v-slot="props" field="" :label="$t('CONTASRECEBERLISTAR.XLSSINTETICO')">
                    <b-button icon-left="download" class="is-danger center" v-if="props.row.qtdeExames > 0" @click.prevent.stop="download('ContasReceberXLSSintetico',props.row,'xls')"></b-button>
                </b-table-column>                                                                                                                        

                <b-table-column v-slot="props" field="Cancelada" label="">
                    <b-icon v-if="props.row.cancelada" type="is-danger" icon="close-circle" title="Cancelada"></b-icon>
                </b-table-column>

                <b-table-column v-slot="props" custom-key="actions" numeric>
                    <router-link :to="{ name: 'contas-receber', params: { id: props.row.id }}" class="button is-small is-primary" title="Editar">
                        <b-icon icon="pencil" size="is-small"></b-icon>
                    </router-link>
                </b-table-column>
            </template>
        </b-table>
    </section>
</template>

<style scoped>
.center {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0 auto;
}
</style>

<script>
    import { mapGetters, mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'

    export default {
        data() {
            return {
                model: [],
                total: 0,
                loading: false,
                procurar: this.$route.query.procurar ?? '',
                ordenarPor: 'Nome',
                ordenar: 'asc',
                defaultOrdenar: 'asc',
                page: 1,
                perPage: 20,
                selecionado: null,
                id: null,                
            }
        },
        components: {
            titlebar
        },
        computed: {
            titleStack() {
                return [
                    this.$t('SIDEBAR.FINANCEIRO'),
                    this.$t('CONTASRECEBERLISTAR.TITULO')
                ]
            },
            ...mapGetters([
                'isInRole'
            ]),
            ...mapState([
                'config',
                'unidadeId',
            ])
        },
        methods: {
           loadAsyncData() {
                const params = [
                    `procurar=${this.procurar}`,
                    `ordem=${this.ordenarPor}.${this.ordenar}`,
                    `page=${this.page}`,
                    `unidadeId=${this.unidadeId}`
                ]

                if (this.$route.query.guiaId) {
                    params.push(`guiaId=${this.$route.query.guiaId}`)
                }

                this.loading = true
                this.$http.get(`/api/financeiro/ListarReceber?${params.join('&')}`)
                    .then(({ data }) => {
                        this.model = data;
                        if (data.lista.length == 0 && this.page > 1) {
                            this.page = 1
                            this.loadAsyncData()
                        }
                        this.total = this.perPage * data.pageCount
                        this.loading = false
                        const queryReplace = { query: { procurar: this.procurar, page: this.page } }
                        if (this.$route.query.guiaId) {
                            queryReplace.query.guiaId = this.$route.query.guiaId
                        }
                        this.$router.replace(queryReplace)
                    })
                    .catch((error) => {
                        this.model = []
                        this.total = 0
                        this.loading = false
                        throw error
                    })
            },
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },
            onSort(field, order) {
                this.ordenarPor = field
                this.ordenar = order
                this.loadAsyncData()
            },
            abrirCadastro() {
                this.$router.push({ path: `/financeiro/receber/${this.selecionado.id}` })
            },
            abrirCadastroInput() {
                this.$router.push({ path: `/financeiro/receber/${this.id}` })
            },
            download(itemName, item){

                if(this.config.gerarXLSFaturamentoServico && itemName == 'ContasReceberXLS'){
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: 'O arquivo está sendo gerado, pode demorar alguns minutos.',
                        type: 'is-info',
                        queue: false
                    })
                    this.$http.get(`/api/financeiro/ContasReceberXlsArquivo?id=${item.id}`);
                }
                else {
                    window.open(`/api/financeiro/${itemName.toLowerCase()}?id=${item.id}`,'_blank');
                }
            }
        },
        mounted() {
            this.loadAsyncData()
        }
    }
</script>