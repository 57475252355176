<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="box">
            <div v-if="isLoading">
                <div class="columns">
                    <div class="column">
                        <b-skeleton animated active></b-skeleton>
                    </div>
                    <div class="column">
                        <b-skeleton animated active></b-skeleton>
                    </div>
                    <div class="column">
                        <b-skeleton animated active></b-skeleton>
                    </div>
                    <div class="column">
                        <b-skeleton animated active></b-skeleton>
                    </div>
                </div>
            </div>
            <div v-for="(item, index) in model.lista" v-bind:key="index" class="panel">
                <div class="panel-heading">
                    <div class="level">
                        <div class="level-left">
                            <div class="level-item">
                                <p class="control">
                                    <span>{{ item.sampleID }}</span>
                                </p>
                            </div>
                        </div>
                        <div class="level-right">
                            <div class="level-item">
                                <p class="control">
                                    <b-button :loading="salvandoDados" size="is-small" type="is-link" icon-left="link-variant" 
                                        @click="vincularAmostra(item.testOrderId)"></b-button> 
                                    <b-button :loading="salvandoDados" size="is-small" type="is-danger" icon-left="delete" 
                                        @click="removerAmostra(item.testOrderId)"></b-button> 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-for="(result, indexResult) in item.results" v-bind:key="indexResult">
                    <div class="columns panel-list-item">
                        <div class="column">
                            <span class="tag">{{ result.lisTestCode }}</span>
                            <b>{{ result.lisTestName }}</b>
                        </div>
                        <div class="column">
                            <p>
                                {{ result.resultName }} {{ result.resultCode }}
                                &#8660;
                                {{ result.lisResultName }}
                            </p>
                            <p v-for="(comment, indexComment) in result.comments" v-bind:key="indexComment" class="has-background-light">
                                <span>{{ comment.commentText }} </span>
                                <small>({{ comment.commentSource }}/{{ comment.commentType }})</small>
                            </p>
                        </div>                       
                        <div class="column has-text-right">
                            <h4 class="is-size-4">
                                <small v-show="result.referencesRange" class="tag is-warning">{{ result.referencesRange }}</small>
                                <span v-show="result.interpretationCode" class="tag is-danger">{{ result.interpretationCode }}</span>                                
                                            
                                <span> {{ result.value }}  </span>
                                <span>{{ result.units }} </span>           
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
            <b-pagination v-if="model.lista != null && model.lista.length > 0"
                    :total="total"
                    :current.sync="current"
                    :range-before="rangeBefore"
                    :range-after="rangeAfter"
                    :per-page="perPage"
                    :icon-prev="prevIcon"
                    :icon-next="nextIcon"
                    aria-next-label="Next page"
                    aria-previous-label="Previous page"
                    aria-page-label="Page"
                    aria-current-label="Current page">
                </b-pagination>
        </div>
    </section>
</template>

<script>
    import titlebar from '@/components/titlebar.vue'

    export default {
        data() {
            return {
                model: [],
                isLoading: true,
                salvandoDados: false,
                total: 1,
                current: 1,
                perPage: 20,
                rangeBefore: 3,
                rangeAfter: 1,
                prevIcon: 'chevron-left',
                nextIcon: 'chevron-right'
            }
        },
        components: {
            titlebar
        },
        computed: {
            titleStack() {
                return [
                    this.$t('SAMPLELINK.INTERFACE'),
                    this.$t('SAMPLELINK.VINCULO'),
                    this.$route.params.id
                ]
            }
        },
        watch: {
            current: function(val){
                this.model = {};
                this.isLoading = true;
                this.carregarLista();                
            }
        },
        methods:{
            carregarLista() {
                this.$http.get('/api/interface/samplelink?id=' + this.$route.params.id + '&page=' + this.current)
                    .then(res => {
                        this.model = res.data
                        this.total = this.perPage * res.data.pageCount
                        this.isLoading = false
                    })
            },
            vincularAmostra(testOrderId){
                this.$buefy.dialog.prompt({
                    title: this.$t('SAMPLELINK.TITULOVINCULAR'),
                    message: this.$t('SAMPLELINK.CONFIRMAVINCULAR'),
                    inputAttrs: {
                        type: 'text',
                        placeholder: this.$t('SAMPLELINK.CODIGO')
                    },
                    confirmText: this.$t('SAMPLELINK.CONFIRMAR'),
                    cancelText: this.$t('SAMPLELINK.CANCELAR'),
                    trapFocus: true,
                    onConfirm: (res) => {
                        this.enviaVinculoAmostra(res, testOrderId);
                    }
                });
            },
            removerAmostra(testOrderId){
                this.$buefy.dialog.confirm({
                    title: this.$t('SAMPLELINK.EXCLUIR'),
                    message:  this.$t('SAMPLELINK.CONFIRMAEXCLUIR'),
                    confirmText: this.$t('SAMPLELINK.CONFIRMAR'),
                    cancelText: this.$t('SAMPLELINK.CANCELAR'),
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        this.enviaRemoverAmostraSemVinculo(testOrderId)
                    }
                })
            },
            enviaRemoverAmostraSemVinculo(testOrderId){
                const vinculoAmostra = {
                    testOrderId: testOrderId, 
                    equipmentId: this.$route.params.id
                };

                this.salvandoDados = true;

                this.$http.post('/api/interface/RemoverAmostraSemVinculo', vinculoAmostra)
                    .then(({ data }) => {
                        this.salvandoDados = false;
                        this.model.lista = this.model.lista.filter(item => item.testOrderId != testOrderId)
                        this.$buefy.snackbar.open({
                            duration: 5000,
                            message: this.$t('SAMPLELINK.AMOSTRAREMOVIDA'),
                            type: 'is-warning',
                            position: 'is-top-right',
                            queue: false
                        })

                    })
                    .catch((error) => {
                        this.salvandoDados = false;
                        this.$buefy.snackbar.open({
                            duration: 5000,
                            message: error.body,
                            type: 'is-danger',
                            position: 'is-top-right',
                            queue: false
                        })
                    });  
            },
            enviaVinculoAmostra(codigoDeBarras, testOrderId){

                const vinculoAmostra = {
                    codigoDeBarras: codigoDeBarras, 
                    testOrderId: testOrderId, 
                    equipmentId: this.$route.params.id
                };

                this.salvandoDados = true;

                this.$http.post('/api/interface/vincularamostra', vinculoAmostra)
                    .then(({ data }) => {
                        this.salvandoDados = false;
                        this.model.lista = this.model.lista.filter(item => item.testOrderId != testOrderId)
                        this.$buefy.snackbar.open({
                            duration: 5000,
                            message: this.$t('SAMPLELINK.AMOSTRAVINCULADA'),
                            type: 'is-success',
                            position: 'is-top-right',
                            queue: false
                        })

                    })
                    .catch((error) => {
                        this.salvandoDados = false;
                        this.$buefy.snackbar.open({
                            duration: 5000,
                            message: error.body,
                            type: 'is-danger',
                            position: 'is-top-right',
                            queue: false
                        })
                    });  
            }
        },
        created(){
            this.carregarLista();
        }
    }
</script>