<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('INCIDENCIAAMOSTRA.REGISTRAR')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification v-if="erroIncidenciaAmostra"
                            type="is-danger">
                {{ erroIncidenciaAmostra }}
            </b-notification>
            <div class="panel">
                <p class="panel-block">
                    <b-field :label="$t('INCIDENCIAAMOSTRA.AMOSTRAS')">
                    </b-field>
                </p>
                <p class="panel-block">
                    <b-tag type="is-info" v-for="(amostra,index) in selecionada" :key="index">{{ amostra.codigoDeBarras }}</b-tag>
                </p>
                <p class="panel-block">
                    <b-field :label="$t('INCIDENCIAAMOSTRA.MOTIVOS')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <b-input v-model="procurarMotivosIncidenciaAmostra"
                             icon="magnify"
                             icon-right="close-circle"
                             icon-right-clickable
                             @icon-right-click="procurarMotivosIncidenciaAmostra = ''"></b-input>
                </div>
                <div class="panel-block">
                    <b-select multiple
                              native-size="10"
                              expanded
                              v-model="motivoIncidenciaAmostraSelecionado">
                        <option v-for="(motivo,index) in filteredMotivosIncidenciaAmostra" :key="index"
                                :value="motivo.id">
                            {{ motivo.descricaoInterno }}
                        </option>
                    </b-select>
                </div>

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-warning"
                      @click="confirmarIncidenciaAmostra">{{$t('INCIDENCIAAMOSTRA.CONFIRMAR')}}</b-button>
            <b-button @click="$emit('close')">{{$t('INCIDENCIAAMOSTRA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    export default {
        props: {
            guia: Object,
            selecionada: Array
        },
        data() {
            return {
                procurarMotivosIncidenciaAmostra: '',
                listaMotivosIncidenciaAmostra: [],
                motivoIncidenciaAmostraSelecionado: [],
                erroIncidenciaAmostra: null,
            }
        },
        computed: {
            filteredMotivosIncidenciaAmostra() {
                return this.procurarMotivosIncidenciaAmostra
                    ? this.listaMotivosIncidenciaAmostra
                        .filter(x =>
                            x.descricaoInterno
                                .toUpperCase()
                                .indexOf(this.procurarMotivosIncidenciaAmostra.toUpperCase()) > -1)
                    : this.listaMotivosIncidenciaAmostra
            },
        },
        mounted() {
            this.loadListaMotivosIncidenciaAmostra()
        },
        methods: {
            loadListaMotivosIncidenciaAmostra() {
                this.$http.get('/api/search/MotivosIncidenciaAmostra')
                    .then(m => {
                        this.listaMotivosIncidenciaAmostra = m.data
                    })
            },
            confirmarIncidenciaAmostra() {
                this.$http.post('/api/atendimento/RegistraIncidenciaAmostra', {
                    guiaId: this.guia.id,
                    id: this.selecionada.map(x => x.id),
                    motivoIds: this.motivoIncidenciaAmostraSelecionado
                })
                    .then((res) => {
                        if (res.body.erro) {
                            this.erroIncidenciaAmostra = res.body.erro
                        } else {
                            this.$emit('loadGuia')
                            this.$emit('close')
                        }
                    })
            },

        }
    }
</script>