<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="tile is-ancestor">
            <div class="tile is-parent is-vertical is-12">
                <article class="tile is-child box">
                    <div class="columns">
                        <div class="column is-12"> 
                            <div class="field has-text-centered">
                                {{subTitle}}
                            </div>
                        </div>
                    </div>
                </article>
                <div class="columns">
                    <div class="column is-6"> 
                        <article class="tile is-child box">
                            <div class="panel">
                                <p class="panel-heading background-1">
                                    {{$t('ESTATISTICAS.CONVENIOS')}}
                                </p>
                                <p class="panel-tabs">
                                    <a :class="{ 'is-active': convenios == 'principais' }" @click="convenios = 'principais'">{{$t('ESTATISTICAS.PRINCIPAIS')}}</a>
                                    <a :class="{ 'is-active': convenios == 'geral' }" @click="convenios = 'geral'">{{$t('ESTATISTICAS.GERAL')}}</a>
                                </p>
                                <div v-if="convenios == 'principais'" class="panel-block is-justified-between">
                                    <bar-chart :chart-data="chartDataConvenio(estatisticasConvenios)" :extra-options="chartOptions" height=300 width=650></bar-chart>
                                </div>
                                <div v-if="convenios == 'geral'" class="panel-block is-justified-between">
                                    <doughnut-chart :chart-data="chartDataConvenioPizza(estatisticasConvenios)" :extra-options="chartOptions" height=300 width=650></doughnut-chart>
                                </div>
                            </div>
                        </article>
                    </div>
                    <div class="column is-6"> 
                        <article class="tile is-child box">
                            <div class="panel">
                                <p class="panel-heading background-2">
                                    {{$t('ESTATISTICAS.SETORES')}}
                                </p>
                                <p class="panel-tabs">
                                    <a :class="{ 'is-active': setores == 'principais' }" @click="setores = 'principais'">{{$t('ESTATISTICAS.PRINCIPAIS')}}</a>
                                    <a :class="{ 'is-active': setores == 'geral' }" @click="setores = 'geral'">{{$t('ESTATISTICAS.GERAL')}}</a>
                                </p>
                                <div v-if="setores == 'principais'" class="panel-block is-justified-between">
                                    <bar-chart :chart-data="chartDataSetor(estatisticasSetores)" :extra-options="chartOptions" height=300 width=650></bar-chart>
                                </div>
                                <div v-if="setores == 'geral'" class="panel-block is-justified-between">
                                    <doughnut-chart :chart-data="chartDataSetorPizza(estatisticasSetores)" :extra-options="chartOptions" height=300 width=650></doughnut-chart>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-6"> 
                        <article class="tile is-child box">
                            <div class="panel">
                                <p class="panel-heading background-1">
                                    {{$t('ESTATISTICAS.FATURAMENTO')}}
                                </p>
                                <table class="table table-bordered table-striped table-hover is-fullwidth">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th class="has-text-right">{{$t('ESTATISTICAS.VALOR')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in estatisticasFaturamento" v-bind:key="index">
                                            <td>{{item.periodo}}</td>
                                            <td class="has-text-right"><b>{{formatPrice(item.valor)}}</b></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </article>
                    </div>
                    <div class="column is-6"> 
                        <article class="tile is-child box">
                            <div class="panel">
                                <p class="panel-heading background-1">
                                    {{$t('ESTATISTICAS.ATENDIMENTOS')}}
                                </p>
                                <table class="table table-bordered table-striped table-hover is-fullwidth">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>{{$t('ESTATISTICAS.GUIAS')}}</th>
                                            <th>{{$t('ESTATISTICAS.PACIENTESNOVOS')}}</th>
                                            <th>{{$t('ESTATISTICAS.EXAMES')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in estatisticasAtendimentos" v-bind:key="index">
                                            <td>{{item.periodo}}</td>
                                            <td>{{item.guias}}</td>
                                            <td>{{item.pacientes}}</td>
                                            <td>{{item.exames}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </article>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-10"> 
                        <article class="tile is-child box">
                            <div class="panel">
                                <p class="panel-heading background-1">
                                    {{$t('ESTATISTICAS.FLUXOCAIXA')}}
                                </p>
                                <div class="panel-block is-justified-between">
                                    <line-chart :chart-data="chartDataFluxo(estatisticasFluxoDeCaixa)" :extra-options="chartOptions" width=1100 height=400></line-chart>
                                </div>
                            </div>
                        </article>
                    </div>
                    <div class="column is-2"> 
                        <div class="panel">
                            <article class="tile is-child box">
                                <ul class="list-group">
                                    <li class="list-group-item"><span style="border-left: 20px solid rgb(0, 0, 255); padding-left: 10px;">{{$t('ESTATISTICAS.RECEBIMENTOS')}}</span></li>
                                    <li class="list-group-item"><span style="border-left: 20px solid rgb(255, 0, 0); padding-left: 10px;">{{$t('ESTATISTICAS.PAGAMENTOS')}}</span></li>
                                    <li class="list-group-item"><span style="border-left: 20px solid rgb(0, 255, 0); padding-left: 10px;">{{$t('ESTATISTICAS.LUCRO')}}</span></li>
                                </ul>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
    import titlebar from '@/components/titlebar.vue'
    import LineChart from '@/components/Charts/LineChart'
    import BarChart from '@/components/Charts/BarChart'
    import DoughnutChart from '@/components/Charts/DoughnutChart'
    import * as chartConfig from '@/components/Charts/chart.config'

    export default {
        components: {
            titlebar,
            LineChart,
            BarChart,
            DoughnutChart
        },
        data() {
            return {
                estatisticasConvenios: [],
                estatisticasSetores: [],
                estatisticasFaturamento: [],
                estatisticasAtendimentos: [],
                estatisticasFluxoDeCaixa: [],
                subTitle: null,
                setores: 'principais',
                convenios: 'principais'
            }
        },
        computed: {
            titleStack() {
                return [
                    this.$t('SIDEBAR.FINANCEIRO'),
                    this.$t('ESTATISTICAS.TITULO')
                ]
            },
            chartOptions() {
                return chartConfig.chartOptionsMain
            }
        },
        mounted() {
            this.loadAsyncData()
        },
        methods: {
            loadAsyncData() {
                this.loading = true;

                this.$http.get(`/api/financeiro/estatisticas?dataInicial=${this.$moment(this.$route.params.dataInicial).format('YYYY-MM-DDTHH:mm:ss')}&dataFinal=${this.$moment(this.$route.params.dataFinal).format('YYYY-MM-DDTHH:mm:ss')}&somenteParticulares=${this.$route.params.somenteParticulares}`)
                    .then(({ data }) => {
                        this.lista = data;
                        this.estatisticasConvenios = data.estatisticasConvenios;
                        this.estatisticasSetores = data.estatisticasSetores;
                        this.estatisticasFaturamento = data.estatisticasFaturamento;
                        this.estatisticasAtendimentos = data.estatisticasAtendimentos;
                        this.estatisticasFluxoDeCaixa = data.estatisticasFluxoDeCaixa;
                        this.subTitle = data.subTitle;

                        this.loading = false;
                    })
                    .catch((error) => {
                        this.lista = [];
                        this.loading = false;
                        throw error;
                    })
            },
            chartDataConvenio(grupo) {
                const convenios = [];

                for (let i = 0; i < grupo.length; i++) {
                    convenios.push({
                            label: grupo[i].convenioNome,
                            backgroundColor: this.getRandomColor(),
                            data: [grupo[i].quantidade],
                        });
                }

                return {
                    datasets: convenios,
                    labels: grupo.map(x => x.convenioNome)
                }
            },  
            chartDataConvenioPizza(grupo) {
                const convenios = [];

                for (let i = 0; i < grupo.length; i++) {
                    convenios.push({ 
                        status: grupo[i].convenioNome,
                        qtde: grupo[i].quantidade * 100 / grupo[i].quantidadeTotal,
                        cor: this.getRandomColor()
                    });
                }

                return convenios;
            }, 
            chartDataSetor(grupo) {
                const setores = [];

                for (let i = 0; i < grupo.length; i++) {
                    setores.push({
                            label: grupo[i].setorNome,
                            backgroundColor: this.getRandomColor(),
                            data: [grupo[i].quantidade]
                        });
                }

                return {
                    datasets: setores,
                    labels: grupo.map(x => x.setorNome)
                }
            },  
            chartDataSetorPizza(grupo) {
                const setores = [];

                for (let i = 0; i < grupo.length; i++) {
                    setores.push({ 
                        status: grupo[i].setorNome,
                        qtde: grupo[i].quantidade * 100 / grupo[i].quantidadeTotal,
                        cor: this.getRandomColor()
                    });
                }

                return setores;
            }, 
            chartDataFluxo(grupo) {
                return {
                    labels:  grupo.map(x => x.periodo),
                    datasets: [
                        {
                            label: this.$t('ESTATISTICAS.RECEBIMENTOS'),
                            fill: false,

                            borderColor: "rgba(0,o,255,0.5)",
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "rgba(0,0,255,1)",
                            pointBorderColor: 'rgba(255,255,255,0)',
                            pointHoverBackgroundColor: "rgba(0,0,255,1)",

                            fillColor: "rgba(0,0,255,0.2)",
                            strokeColor: "rgba(0,0,255,1)",
                            pointColor: "rgba(0,0,255,1)",
                            pointStrokeColor: "#fff",
                            pointHighlightFill: "#fff",
                            pointHighlightStroke: "rgba(0,0,255,1)",
                            data: grupo.map(x => x.valorReceber)
                        },
                        {
                            label: this.$t('ESTATISTICAS.PAGAMENTOS'),
                            fill: false,

                            borderColor: "rgba(255,0,0,0.5)",
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "rgba(255,0,0,1)",
                            pointBorderColor: 'rgba(255,255,255,0)',
                            pointHoverBackgroundColor: "rgba(255,0,0,1)",

                            fillColor: "rgba(255,0,0,0.2)",
                            strokeColor: "rgba(255,0,0,1)",
                            pointColor: "rgba(255,0,0,1)",
                            pointStrokeColor: "#fff",
                            pointHighlightFill: "#fff",
                            pointHighlightStroke: "rgba(255,0,0,1)",
                            data: grupo.map(x => x.valorPagar)
                        },
                        {
                            label: this.$t('ESTATISTICAS.LUCRO'),
                            fill: false,

                            borderColor: "rgba(0,255,0,0.5)",
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "rgba(0,255,0,1)",
                            pointBorderColor: 'rgba(255,255,255,0)',
                            pointHoverBackgroundColor: "rgba(0,255,0,1)",

                            fillColor: "rgba(0,255,0,0.2)",
                            strokeColor: "rgba(0,255,0,1)",
                            pointColor: "rgba(0,255,0,1)",
                            pointStrokeColor: "#fff",
                            pointHighlightFill: "#fff",
                            pointHighlightStroke: "rgba(0,255,0,1)",
                            data: grupo.map(x => x.valorReceber - x.valorPagar > 0 ? x.valorReceber - x.valorPagar : 0)
                        }
                    ]
                }
            },           
            formatPrice(value) {
                const val = (value/1).toFixed(2).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            },
            getRandomColor() {
                const letters = '0123456789ABCDEF';
                let color = '#';
                for (let i = 0; i < 6; i++) {
                    color += letters[Math.floor(Math.random() * 16)];
                }
                return color;
            }
        }
    }
</script>